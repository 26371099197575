import React from "react";
import {
  Edit,
  SimpleForm,
  SelectInput,
  TextInput,
  NumberInput,
} from "react-admin";
import { mediaStati } from "../../data/common";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const PageEdit = (props) => {
  return (
    <Edit title="Edit Page" {...props} mutationMode="pessimistic">
      <Card>
        <CardContent>
          <h2>Page</h2>
          <p>
            See google doc instructions
            https://docs.google.com/document/d/1SadzDSeJ53TWPdEE1VrtPwG_elx2KMvkg-kMGLpYRBE/edit#heading=h.nhdh5s1yts4o
          </p>
        </CardContent>
      </Card>
      <SimpleForm>
        <h2>Edit Page</h2>
        <TextInput disabled source="id" />

        {/* Not used currently */}
        {/* <TextInput source="image_link" /> */}
        <NumberInput source="order" />
        <SelectInput source="status" choices={mediaStati} />
        <TextInput source="next_btn_text" />
      </SimpleForm>
    </Edit>
  );
};

export default PageEdit;
