import {
  Admin,
  Resource,
  fetchUtils,
  defaultTheme,
  Title,
  withLifecycleCallbacks,
  // CustomRoutes,
} from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
// import { Route } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// **** Icons!
// https://mui.com/material-ui/material-icons/?query=mounta&selected=Landscape
import PeopleIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import LandscapeIcon from "@mui/icons-material/Landscape";
import EmojiPeople from "@mui/icons-material/EmojiPeople";
import WebStoriesIcon from "@mui/icons-material/WebStories";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MailTwoTone from "@mui/icons-material/MailTwoTone";
import LinkTwoTone from "@mui/icons-material/LinkTwoTone";
import QuizIcon from "@mui/icons-material/Quiz";
import ArticleIcon from "@mui/icons-material/Article";

import authProvider from "./authProvider";
import { apiBase } from "./api/requests";
import { getItem } from "./utils/localStorage";

import GroupsList from "./resources/groups/GroupsList";
import GroupsCreate from "./resources/groups/GroupsCreate";
import GroupsEdit from "./resources/groups/GroupsEdit";

import HKRawList from "./resources/hkraw/HKRawList";

import UsersList from "./resources/users/UsersList";
import UserEdit from "./resources/users/UserEdit";
import UserShow from "./resources/users/UserShow";
// import { ChorusChat } from "./Chat";
import { Layout } from "react-admin";
import { ChorusMenu, ChorusAppBar } from "./Menu";
import MediaList from "./resources/media/MediaList";
import MediaEdit from "./resources/media/MediaEdit";
import MediaCreate from "./resources/media/MediaCreate";
import AudiobgsList from "./resources/audiobgs/AudiobgsList";
import AudiobgsShow from "./resources/audiobgs/AudiobgsShow";
import LogList from "./resources/logs/LogList";
import LogShow from "./resources/logs/LogShow";
import SoundiconShow from "./resources/audiobgs/SoundiconShow";
import SoundiconList from "./resources/audiobgs/SoundiconsList";
import CoachusersList from "./resources/coachusers/CoachusersList";
import CoachusersEdit from "./resources/coachusers/CoachusersEdit";
import LessonsList from "./resources/lessons/LessonsList";
import LessonsEdit from "./resources/lessons/LessonsEdit";
import { Quiz } from "@mui/icons-material";
import QuizquestionsList from "./resources/quizquestions/QuizquestionsList";
import QuizquestionsEdit from "./resources/quizquestions/QuizquestionsEdit";
import MailmsgCreate from "./resources/mailmsgs/MailmsgCreate";
import MailmsgList from "./resources/mailmsgs/MailmsgList";
import MailmsgShow from "./resources/mailmsgs/MailmsgShow";
import MailmsgLinkList from "./resources/mailmsglinks/MailmsglinkList";
import MailmsglinkCreate from "./resources/mailmsglinks/MailmsglinkCreate";
import MailMsgLinkEdit from "./resources/mailmsglinks/MailmsglinkEdit";
import MailmsgLinkShow from "./resources/mailmsglinks/MailmsglinkShow";
import PageList from "./resources/pages/PageList";
import PageEdit from "./resources/pages/PageEdit";
import UserCreateB2BLoaded from "./resources/users/UserCreate";

const ChorusLayout = (props) => (
  <Layout {...props} menu={ChorusMenu} appBar={ChorusAppBar} />
);

// https://marmelab.com/react-admin/Theming.html#global-theme-overrides
const theme = {
  ...defaultTheme,
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#4f3cc9",
    },
    secondary: {
      light: "#5f5fc4",
      main: "#283593",
      dark: "#001064",
      contrastText: "#fff",
    },
    background: {
      default: "#fcfcfe",
    },
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #fff",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #4f3cc9",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "none",
        },
        root: {
          border: "1px solid #e0e0e3",
          backgroundClip: "padding-box",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#808080",
          backgroundColor: "#fff",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        height: "80%",
        maxHeight: "80%",
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#f5f5f5",
        },
        barColorPrimary: {
          backgroundColor: "#d7d7d7",
        },
      },
    },
  },
};

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const token = getItem("auth_token");
  console.log(`Making HTTP req with ${token}`);
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = withLifecycleCallbacks(
  simpleRestProvider(`${apiBase()}/admin`, httpClient),
  [
    {
      /**
       * For posts update only, convert uploaded images to base 64 and attach them to
       * the `picture` sent property, with `src` and `title` attributes.
       */
      resource: "media",
      beforeUpdate: async (params, dataProvider) => {
        // Freshly dropped waveform_images are File objects and must be converted to base64 strings
        const newPictures = params.data.waveform_images.filter(
          (p) => p.rawFile instanceof File
        );
        const formerPictures = params.data.waveform_images.filter(
          (p) => !(p.rawFile instanceof File)
        );

        const base64Pictures = await Promise.all(
          newPictures.map(convertFileToBase64)
        );
        const waveform_images = [
          ...formerPictures,
          ...base64Pictures.map((dataUrl, index) => ({
            src: dataUrl,
            title: newPictures[index].title,
          })),
        ];
        console.log("waveform_images!!!!!!!!!!!!!!!!!!!");
        console.log(waveform_images);

        return {
          ...params,
          data: { ...params.data, waveform_images },
        };
        // This was from the example but it doesn't work
        // return dataProvider.update(resource, {
        //   id: params.id,
        //   data: { ...params.data, waveform_images },
        // });
      },
    },
  ]
);

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

const Home = () => {
  return (
    <Card>
      <Title title="Welcome to Chorus Sleep Admin" />
      <CardContent>
        <p>This page has very little information. Please use the left nav!</p>
      </CardContent>
    </Card>
  );
};

export default function App() {
  // Big hack but add custom methods here. If needed we can link to methods
  // defined in other files
  dataProvider.mytest = () => {
    return httpClient(`${apiBase()}/admin/mediaupdate30`, {
      method: "GET",
    }).then((response) => response.json());
  };
  return (
    <Admin
      dashboard={Home}
      theme={theme}
      title={"ChorusSleep Admin"}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={ChorusLayout}
    >
      <Resource
        name="audiobgs"
        list={AudiobgsList}
        show={AudiobgsShow}
        icon={LandscapeIcon}
      />

      <Resource
        name="coachusers"
        list={CoachusersList}
        edit={CoachusersEdit}
        icon={EmojiPeople}
      />

      <Resource
        name="groups"
        list={GroupsList}
        create={GroupsCreate}
        edit={GroupsEdit}
        icon={PeopleIcon}
      />
      <Resource name="hkraw" list={HKRawList} />

      <Resource
        name="lessons"
        list={LessonsList}
        edit={LessonsEdit}
        // show={LogShow}
        icon={MenuBookIcon}
      />

      <Resource
        name="pages"
        list={PageList}
        edit={PageEdit}
        // show={LogShow}
        icon={ArticleIcon}
      />

      <Resource
        name="quizquestions"
        list={QuizquestionsList}
        edit={QuizquestionsEdit}
        // show={LogShow}
        icon={QuizIcon}
      />

      <Resource
        name="logs"
        list={LogList}
        show={LogShow}
        icon={WebStoriesIcon}
      />

      <Resource
        name="media"
        list={MediaList}
        create={MediaCreate}
        edit={MediaEdit}
        icon={PlayCircleOutlineIcon}
      />

      <Resource
        name="mailmsgs"
        list={MailmsgList}
        create={MailmsgCreate}
        show={MailmsgShow}
        icon={MailTwoTone}
      />
      <Resource
        name="mailmsglinks"
        list={MailmsgLinkList}
        create={MailmsglinkCreate}
        edit={MailMsgLinkEdit}
        show={MailmsgLinkShow}
        icon={LinkTwoTone}
      />
      <Resource
        name="soundicons"
        list={SoundiconList}
        show={SoundiconShow}
        icon={LandscapeIcon}
      />
      <Resource
        name="users"
        list={UsersList}
        edit={UserEdit}
        icon={PersonIcon}
        show={UserShow}
        create={UserCreateB2BLoaded}
      />
      {/* <CustomRoutes>
        <Route path="/chat" element={<ChorusChat />} />
      </CustomRoutes> */}
    </Admin>
  );
}
