import React from "react";
import { List, Datagrid, TextField, NumberField, DateField } from "react-admin";
import { Pag } from "../../utils/utils";

const PageList = (props) => {
  return (
    <List {...props} pagination={<Pag />} perPage={200}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="lesson_id" />
        <NumberField source="order" />
        <TextField source="status" />
        <TextField source="next_btn_text" />
        <DateField source="created" />
        <DateField source="updated" />
      </Datagrid>
    </List>
  );
};

export default PageList;
