import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  ImageField,
  SelectInput,
  CreateButton,
  RefreshButton,
  useDataProvider,
  NumberField,
  Button,
  FilterButton,
  BooleanInput,
  useRecordContext,
} from "react-admin";
import { useMutation } from "react-query";
import {
  mediaDurations,
  mediaReviewStati,
  mediaStati,
  mediaTypes,
  teachers,
} from "../../data/common";
import { Pag } from "../../utils/utils";
// import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";

const mediaFilters = [
  <SelectInput source="author_id" choices={teachers} alwaysOn />,
  <SelectInput source="duration" choices={mediaDurations} alwaysOn />,
  <SelectInput source="status" choices={mediaStati} alwaysOn />,
  <SelectInput source="type" choices={mediaTypes} alwaysOn />,
  <SelectInput source="status_review" choices={mediaReviewStati} alwaysOn />,
  <BooleanInput source="featured" />,
];

const PostActions = ({ basePath }) => (
  <CardActions>
    <FilterButton />
    <CreateButton basePath={basePath} />

    <RefreshButton />
    {/* Add your custom actions */}
    <Update30 />
  </CardActions>
);

const Update30 = () => {
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(["update30"], () =>
    dataProvider.mytest()
  );
  return (
    <Button label="Update30" onClick={() => mutate()} disabled={isLoading} />
  );
};

const ReviewTextField = (props) => {
  const record = useRecordContext(props);

  // ensure the record exists
  if (!record) {
    return null;
  }

  const value = record[props.source];

  let style = {};
  if (value === "failed" || value === "concern") {
    style.color = "red";
  } else if (
    value === "passed" ||
    value === "ok" ||
    value === "good" ||
    value === "great"
  ) {
    style.color = "green";
  }

  return <TextField style={style} {...props} />;
};

const MediaList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pag />}
      perPage={200}
      filters={mediaFilters}
      actions={<PostActions />}
    >
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="author_id" />

        <ReviewTextField source="status_review" />

        <ImageField
          sx={{ "& .RaImageField-image": { maxHeight: 200, maxWidth: 200 } }}
          source="background_image"
        />
        <ImageField source="logo_image" />
        <TextField source="type" />
        <TextField source="tags" />
        <TextField source="featured" />
        <TextField source="status" />
        <NumberField source="duration" />
        <DateField source="live_at" showTime={true} />
        <DateField source="created" showTime={true} />
        <DateField source="updated" showTime={true} />
      </Datagrid>
    </List>
  );
};

export default MediaList;
