import React from "react";
import {
  TextField,
  DateField,
  SimpleShowLayout,
  Show,
  FunctionField,
} from "react-admin";
import { Divider } from "@mui/material";
import { apiBaseURL } from "../../utils/utils";

const MailmsgLinkShow = (props) => {
  return (
    <Show>
      <SimpleShowLayout
        spacing={2}
        divider={<Divider flexItem />}
        sx={{ "& .RaLabeled-label": { fontWeight: "bold" } }}
      >
        <FunctionField
          label="Test link"
          render={(record) => (
            <div style={{ marginTop: 5 }}>
              <a
                href={apiBaseURL() + `/s/${record.id}/no-user-id.gif`}
                target="_blank"
                rel="noreferrer"
              >
                {apiBaseURL() + `/s/${record.id}/no-user-id.gif`}
              </a>
            </div>
          )}
        />
        <TextField source="id" />
        <TextField source="user_id" />
        <TextField source="email_name" />
        <TextField source="from_id" />
        <TextField source="status" />
        <DateField source="created" />
        <DateField source="updated" />
      </SimpleShowLayout>
    </Show>
  );
};

export default MailmsgLinkShow;
