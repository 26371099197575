import React from "react";
import { ImageField, Show, SimpleShowLayout, TextField } from "react-admin";
import { Divider } from "@mui/material";

const AudiobgsShow = (props) => {
  return (
    <Show>
      <SimpleShowLayout
        spacing={2}
        divider={<Divider flexItem />}
        sx={{ "& .RaLabeled-label": { fontWeight: "bold" } }}
      >
        <TextField source="id" />
        <TextField source="url" />
        <ImageField
          title="Image Preview"
          sx={{ "& .RaImageField-image": { maxHeight: 500, maxWidth: 500 } }}
          source="url"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default AudiobgsShow;
