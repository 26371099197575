import React from "react";
import {
  Edit,
  SimpleForm,
  SelectInput,
  TextInput,
  NumberInput,
  Datagrid,
  ArrayField,
  BooleanField,
  DateField,
  TextField,
  NumberField,
} from "react-admin";
import { mediaStati } from "../../data/common";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// Custom label with enlarged font (to help differentiate insights and resources)
const CustomLabel = ({ text }) => (
  <div style={{ fontSize: "1.5em", paddingBottom: "20px" }}>{text}</div>
);

const LessonsEdit = (props) => {
  return (
    <Edit title="Edit Lesson" {...props} mutationMode="pessimistic">
      <Card>
        <CardContent>
          <h2>Lesson Edit Summary</h2>
          {/* <h4 style={{ color: "orange" }}> */}
          <p>
            Review pages and quiz questions for this lesson below. Note that the
            sum of ACTIVE pages + ACTIVE quizzes should be equal to the total
            items of the lesson. This gets set automatically by the content
            publishing process. Also, the ordering should be correct for both
            types of items combined.
          </p>
        </CardContent>
      </Card>
      <SimpleForm>
        <h2>Edit Lesson</h2>
        <TextInput disabled source="id" />
        <NumberInput source="day" />
        <TextInput source="description" />
        <NumberInput source="duration" />

        {/* Not used currently */}
        {/* <TextInput source="image_link" /> */}
        <NumberInput source="order" />
        <SelectInput source="status" choices={mediaStati} />

        <TextInput
          source="total_items"
          disabled
          helperText="Set automatically by the publish process. Ask Warren for help if broken"
        />
        <TextInput source="title" fullWidth />
        <h2>Pages</h2>
        <ArrayField source="pages">
          <Datagrid bulkActionButtons={false}>
            <DateField source="created" showTime={false} />
            <DateField source="updated" showTime={false} />
            <TextField source="id" />
            <TextField source="lesson_id" />
            <TextField source="next_btn_text" />
            <NumberField source="order" />
            <TextField source="status" />
          </Datagrid>
        </ArrayField>

        <h2>Quiz Questions</h2>
        <ArrayField source="quiz_questions">
          <Datagrid bulkActionButtons={false}>
            <DateField source="created" showTime={false} />
            <DateField source="updated" showTime={false} />
            <TextField source="answer" />
            <TextField source="category" />
            <TextField source="choice1" />
            <TextField source="choice2" />
            <TextField source="choice3" />
            <TextField source="choice4" />
            <TextField source="choice5" />
            <TextField source="choice6" />
            <TextField source="description" />
            <TextField source="id" />
            <TextField source="image_link" />
            <TextField source="lesson_id" />
            <NumberField source="order" />
            <TextField source="question" />
            <TextField source="status" />
            <TextField source="title" />
            <TextField source="type" />
            <TextField source="desc_wrong" />
          </Datagrid>
        </ArrayField>
      </SimpleForm>
    </Edit>
  );
};

export default LessonsEdit;
