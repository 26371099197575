import { getItem } from "../utils/localStorage";

export const contentHeaders = {
  "Content-Type": "application/json",
};

export function apiBase() {
  if (!process.env.REACT_APP_API_ENDPOINT) {
    throw new Error("API ENDPOINT NOT SET!");
  }
  console.log(`User API EP ${process.env.REACT_APP_API_ENDPOINT}`);

  return process.env.REACT_APP_API_ENDPOINT;
}

export const getAuthToken = () => {
  const authToken = getItem("auth_token");
  return `Bearer ${authToken}`;
};

export const getAuthenticatedHeaders = () => {
  const tkn = getAuthToken();
  const hdr = {
    ...contentHeaders,
  };
  if (tkn) {
    hdr.Authorization = tkn;
  }

  return hdr;
};

export const createReqObject = (url, method, headers, data = {}) => ({
  url: `${apiBase()}/admin/${url}`,
  headers,
  data: JSON.stringify(data),
  method,
});

export const createBasicReqObject = (url, method, headers, data = {}) => ({
  url: `${apiBase()}/${url}`,
  headers,
  data: JSON.stringify(data),
  method,
});

export const loginWithCode = (data) =>
  createBasicReqObject(`users/code`, "POST", contentHeaders, data);

export const loginSendCode = (data) =>
  createBasicReqObject(`users`, "POST", contentHeaders, data);
