// in src/Menu.js
import * as React from "react";
import {
  AppBar,
  DashboardMenuItem,
  Menu,
  MenuItemLink,
  Logout,
  useResourceDefinitions,
  useSidebarState,
  UserMenu,
} from "react-admin";
import DefaultIcon from "@mui/icons-material/ViewList";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import { getItem } from "./utils/localStorage";

export const ChorusMenu = (props) => {
  const resources = useResourceDefinitions();
  const [open] = useSidebarState();
  return (
    <Menu {...props}>
      <DashboardMenuItem />
      {Object.keys(resources).map((name) => {
        const res = resources[name];
        return (
          <MenuItemLink
            key={name}
            to={`/${name}`}
            primaryText={
              (res.options &&
                resources[name].options.label.replace(/^./, (str) =>
                  str.toUpperCase()
                )) ||
              name.replace(/^./, (str) => str.toUpperCase())
            }
            leftIcon={res.icon ? <res.icon /> : <DefaultIcon />}
            onClick={props.onMenuClick}
            sidebarIsOpen={open}
          />
        );
      })}
      {/* add your custom menus here */}
      {/* <MenuItemLink
        key="chat"
        to={`/chat`}
        primaryText={"Stream Chat"}
        leftIcon={<ChatIcon />}
        sidebarIsOpen={open}
      /> */}
    </Menu>
  );
};

const MyCustomIcon = () => {
  const u = getItem("user") || {};

  return (
    <Avatar
      sx={{
        height: 30,
        width: 30,
      }}
      src={u.profile_image_link}
    />
  );
};

const MyUserMenu = (props) => {
  const u = getItem("user") || {};
  return (
    <UserMenu {...props} icon={<MyCustomIcon />}>
      {/* <MenuItem>{u.id}</MenuItem> */}
      <Typography variant="body2" m={2} component="li">
        {u.nickname}
      </Typography>
      <Typography variant="body2" m={2} component="li">
        {u.email}
      </Typography>
      <Typography variant="body2" m={2} component="li">
        {u.id}
      </Typography>
      <MenuItemLink
        to={`/users/${u.id}/show`}
        primaryText="View My User"
        leftIcon={<PersonIcon />}
        // component="li"
      />
      <MenuItemLink
        to={`/users/${u.id}`}
        primaryText="Edit My User"
        leftIcon={<SettingsIcon />}
        // component="li"
      />
      {/* Wow what a hack */}
      <Logout sx={{ marginLeft: 0.4 }} />
    </UserMenu>
  );
};

export const ChorusAppBar = (props) => {
  return <AppBar {...props} userMenu={<MyUserMenu />} />;
};
