import React from "react";
import { List, Datagrid, TextField, BooleanField } from "react-admin";
import { Pag } from "../../utils/utils";

const GroupsList = (props) => {
  return (
    <List {...props} pagination={<Pag />} perPage={200}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <BooleanField source="is_b2b" />
        <BooleanField source="real_user" />
        <BooleanField source="premium" />
        <TextField source="email_domains" />
        <TextField source="staff_notes" />
        <TextField source="status" />
        <BooleanField source="real_user" />
      </Datagrid>
    </List>
  );
};

export default GroupsList;
