import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

const GroupsCreate = (props) => {
  return (
    <Create title="Create a Group" {...props}>
      <SimpleForm>
        <TextInput source="id" />
      </SimpleForm>
    </Create>
  );
};

export default GroupsCreate;
