import React from "react";
import { Create, SimpleForm, TextInput, SelectInput } from "react-admin";
import { mediaTypes, teachers } from "../../data/common";

const MediaCreate = (props) => {
  return (
    <Create title="Create Media Session or Sound" {...props}>
      <SimpleForm>
        <TextInput source="title" helperText="Title Case Like This" />
        <SelectInput source="type" choices={mediaTypes} />
        <SelectInput
          source="author_id"
          choices={teachers}
          helperText="Leave blank for sounds and music"
        />
      </SimpleForm>
    </Create>
  );
};

export default MediaCreate;
