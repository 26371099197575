import React from "react";
import { Create, SimpleForm, TextInput, SelectInput } from "react-admin";
import { mailMsgLinkTypes } from "../../data/common";

const MailmsglinkCreate = (props) => {
  return (
    <Create title="Send a test email" {...props}>
      <SimpleForm>
        <TextInput
          source="id"
          fullWidth
          helperText="enter a slug-like id or leave blank"
        />
        <TextInput source="campaign_id" required fullWidth />
        <TextInput source="link" fullWidth />
        <SelectInput source="type" required choices={mailMsgLinkTypes} />
      </SimpleForm>
    </Create>
  );
};

export default MailmsglinkCreate;
