import React from "react";
import {
  Edit,
  SimpleForm,
  ArrayField,
  Datagrid,
  TextInput,
  TextField,
  WithRecord,
  Link,
  DateField,
  BooleanField,
} from "react-admin";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const CoachusersEdit = (props) => {
  return (
    <Edit title="Message User" {...props} mutationMode="pessimistic">
      <Card>
        <CardContent>
          <h2>User Summary</h2>
          <h4 style={{ color: "orange" }}>
            **PLEASE NOTE** This page will NOT automatically refresh! Please
            hard refresh this page to check for new messages, or you can stay on
            the previous list page which WILL auto refresh
          </h4>
          <WithRecord
            render={(record) => (
              <table>
                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>UserID:</th>
                  <td style={{ padding: "5px" }}>{record.user?.id}</td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>Email: </th>
                  <td style={{ padding: "5px" }}>{record.user?.email}</td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Nickname:{" "}
                  </th>
                  <td style={{ padding: "5px" }}>{record.user?.nickname}</td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Gender:{" "}
                  </th>
                  <td style={{ padding: "5px" }}>{record.settings?.sex}</td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Age Range:{" "}
                  </th>
                  <td style={{ padding: "5px" }}>
                    {record.settings?.age_range}
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    User's Info Page:{" "}
                  </th>
                  <td style={{ padding: "5px" }}>
                    <Link to={`/users/${record.id}/show`}>click here</Link>
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    User's MixPanel Page:{" "}
                  </th>
                  <td style={{ padding: "5px" }}>
                    <a
                      className="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways RaLink-link css-9zqd5s-MuiTypography-root-MuiLink-root"
                      href={`https://mixpanel.com/project/2695120/view/3231852/app/profile#distinct_id=${record.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      click here
                    </a>
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Notifications Set?:{" "}
                  </th>
                  <td style={{ padding: "5px" }}>
                    <span
                      style={{
                        color: record.settings?.notification_set ? "" : "red",
                      }}
                    >
                      {`${record.settings?.notification_set}`}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Bed/Wake Setting:{" "}
                  </th>
                  <td
                    style={{ padding: "5px" }}
                  >{`${record.settings?.bedtime_hour}:${record.settings?.bedtime_min} ${record.settings?.wake_hour}:${record.settings?.wake_min}`}</td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Notif Bed/Wake Setting:{" "}
                  </th>
                  <td
                    style={{ padding: "5px" }}
                  >{`${record.settings?.notification_bedtime_hour}:${record.settings?.notification_bedtime_min} ${record.settings?.notification_slog_hour}:${record.settings?.notification_slog_min}`}</td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Notif Bed Days Goal:{" "}
                  </th>
                  <td style={{ padding: "5px" }}>
                    {record.settings?.notification_bedtime_days
                      .split()
                      .join(", ")}
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Notif Morning Days:{" "}
                  </th>
                  <td style={{ padding: "5px" }}>
                    {record.settings?.notification_slog_days.split().join(", ")}
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Sleep Goal:{" "}
                  </th>
                  <td style={{ padding: "5px" }}>
                    {record.settings?.sleep_goal_many.join(", ")}
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Nights Per Week:{" "}
                  </th>
                  <td style={{ padding: "5px" }}>
                    {record.settings?.nights_per_week}
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Num HealthKit Records:
                  </th>
                  <td style={{ padding: "5px" }}>
                    <span
                      style={{ color: record.user_hk_records > 0 ? "" : "red" }}
                    >
                      {record.user_hk_records}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Total Session Seconds:
                  </th>
                  <td style={{ padding: "5px" }}>
                    <span
                      style={{
                        color: record.total_session_secs > 600 ? "" : "red",
                      }}
                    >
                      {record.total_session_secs}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Total Sound Seconds:
                  </th>
                  <td style={{ padding: "5px" }}>
                    <span
                      style={{
                        color: record.total_sound_secs > 600 ? "" : "red",
                      }}
                    >
                      {record.total_sound_secs}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th style={{ textAlign: "left", padding: "5px" }}>
                    Max Lesson Day:
                  </th>
                  <td style={{ padding: "5px" }}>
                    <span
                      style={{ color: record.max_lesson_day > 6 ? "" : "red" }}
                    >
                      {record.max_lesson_day}
                    </span>
                  </td>
                </tr>
              </table>
            )}
          />
        </CardContent>
      </Card>

      <SimpleForm>
        <h2>Send a New Message</h2>

        <TextInput source="message" multiline fullWidth />
        <h2>Previous Messages</h2>

        <ArrayField source="messages">
          <Datagrid bulkActionButtons={false}>
            <DateField source="created" showTime={true} />
            <BooleanField source="read" />
            <BooleanField source="from_user" />

            <TextField source="message" />
          </Datagrid>
        </ArrayField>
      </SimpleForm>
    </Edit>
  );
};

export default CoachusersEdit;
