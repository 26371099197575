import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
} from "react-admin";
import { Pag } from "../../utils/utils";

const LessonsList = (props) => {
  return (
    <List {...props} pagination={<Pag />} perPage={200}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="lesson_id" />
        <NumberField source="day" />
        <NumberField source="duration" />
        <NumberField source="order" />
        <TextField source="status" />
        <TextField source="title" />
        <NumberField source="total_items" />
      </Datagrid>
    </List>
  );
};

export default LessonsList;
