import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ImageField,
  ShowButton,
} from "react-admin";
import { Pag } from "../../utils/utils";

const AudiobgsList = (props) => {
  return (
    <List {...props} pagination={<Pag />} perPage={200}>
      <Datagrid bulkActionButtons={false} rowClick="">
        <ImageField
          sx={{ "& .RaImageField-image": { maxHeight: 400, maxWidth: 400 } }}
          source="url"
        />
        <TextField source="url" />
        <TextField source="id" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default AudiobgsList;
