import React from "react";
import {
  Edit,
  SimpleForm,
  SelectInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  NumberInput,
  AutocompleteArrayInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { groupChartTypes, mediaStati } from "../../data/common";

// Custom label with enlarged font (to help differentiate insights and resources)
const CustomLabel = ({ text }) => (
  <div style={{ fontSize: "1.5em", paddingBottom: "20px" }}>{text}</div>
);

const GroupsEdit = (props) => {
  return (
    <Edit
      title="Edit Group"
      {...props}
      mutationMode="pessimistic"
      redirect={false}
    >
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="name" fullWidth />
        <BooleanInput source="is_b2b" />
        {/* <BooleanInput source="chat_enabled" /> */}
        <BooleanInput source="real_user" />
        <BooleanInput source="premium" />
        <BooleanInput source="gifts_enabled" />
        <BooleanInput source="daytime_enabled" />
        <TextInput source="logo_small_link" fullWidth />
        <TextInput source="logo_large_link" fullWidth />
        {/* <TextInput source="short_code" /> */}
        <TextInput source="staff_notes" multiline fullWidth />
        <TextInput
          source="contract_start"
          helperText="Like 2024-01 to specify the start of the actual contract for use with queries in the leadership dashboard. Leave empty to omit"
        />

        <SelectInput source="status" choices={mediaStati} />
        <ArrayInput
          source="email_domains"
          label={<CustomLabel text="Email Domains" />}
          helperText="EG @chorussleep.com"
        >
          <SimpleFormIterator>
            <TextInput />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          source="emails"
          label={<CustomLabel text="Emails" />}
          helperText="EG full email like ali@chorussleep.com"
        >
          <SimpleFormIterator>
            <TextInput type="email" />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput source="dash_enabled" />
        <BooleanInput source="dash_charts_enabled" />
        <AutocompleteArrayInput
          source="dash_charts_approved"
          choices={groupChartTypes}
        />

        <ArrayInput
          source="admin_emails"
          label={<CustomLabel text="Admin Emails" />}
          helperText="EG full email like hr-leader@wcg.com"
        >
          <SimpleFormIterator>
            <TextInput type="email" />
          </SimpleFormIterator>
        </ArrayInput>
        <NumberInput source="num_employees" label="Eligible Employees" />
        <NumberInput source="num_active" label="Adopted Employees" />
        <NumberInput source="num_engaged" label="Engaged Employees" />

        <ArrayInput source="resources" label={<CustomLabel text="Resources" />}>
          <SimpleFormIterator inline>
            <TextInput source="title" helperText={false} />
            <TextInput
              source="bg_color"
              helperText="A hex color like #23fa59"
            />
            <RichTextInput source="content" helperText={false} />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="insights" label={<CustomLabel text="Insights" />}>
          <SimpleFormIterator inline>
            <SelectInput
              source="icon"
              choices={[
                { id: "", name: "<blank>" },
                { id: "green_check", name: "green_check" },
                { id: "red_dot", name: "red_dot" },
                { id: "blue_star", name: "blue_star" },
                // We can add more options here in the future
              ]}
            />
            <TextInput source="title" helperText={false} />
            <RichTextInput source="content" helperText={false} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default GroupsEdit;
