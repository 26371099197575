import React from "react";
import { Create, SimpleForm, TextInput, SelectInput } from "react-admin";
import { emailFromIDs, emailTypes } from "../../data/common";

const MailmsgCreate = (props) => {
  return (
    <Create title="Send a test email" {...props}>
      <SimpleForm>
        <TextInput source="subject" fullWidth />
        <TextInput source="body" fullWidth />
        <TextInput source="user_id_to" fullWidth />
        <SelectInput source="from_id" choices={emailFromIDs} />
        <SelectInput source="type" choices={emailTypes} />
      </SimpleForm>
    </Create>
  );
};

export default MailmsgCreate;
