import React from "react";
import {
  Edit,
  SimpleForm,
  SelectInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  NumberInput,
  AutocompleteArrayInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { groupChartTypes, mediaStati } from "../../data/common";

// Custom label with enlarged font (to help differentiate insights and resources)
const CustomLabel = ({ text }) => (
  <div style={{ fontSize: "1.5em", paddingBottom: "20px" }}>{text}</div>
);

const QuizquestionsEdit = (props) => {
  return (
    <Edit title="Edit Quiz Question" {...props} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput disabled source="created" />
        <TextInput disabled source="updated" />
        <TextInput disabled source="category" />
        <TextInput disabled source="lesson_id" />
        <TextInput disabled source="image_link" />
        <TextInput disabled source="type" />

        <NumberInput source="order" />
        <SelectInput source="status" choices={mediaStati} />

        <TextInput source="question" />
        <TextInput source="answer" />
        <TextInput source="choice1" />
        <TextInput source="choice2" />
        <TextInput source="choice3" />
        <TextInput source="choice4" />
        <TextInput source="choice5" />
        <TextInput source="choice6" />
        <TextInput source="description" />
        <TextInput source="desc_wrong" />
        <TextInput source="title" />
      </SimpleForm>
    </Edit>
  );
};

export default QuizquestionsEdit;
