import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

const UserCreateB2BLoaded = (props) => {
  return (
    <Create title={`Create a new "B2B Loaded" user`} {...props}>
      <SimpleForm>
        <TextInput
          type="email"
          source="email"
          helperText="Email address"
          fullWidth
        />
        <TextInput
          source="nickname"
          helperText="Full name, name, or nickname"
          fullWidth
        />
        <TextInput
          source="timezone"
          helperText="Eg America/Los_Angeles "
          fullWidth
        />
        <TextInput source="group" helperText="Group eg tag" fullWidth />
        <TextInput source="gender" helperText="male or female" fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default UserCreateB2BLoaded;
