import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { Pag } from "../../utils/utils";

const coachFilters = [
  <TextInput source="email" alwaysOn />,
  <TextInput source="group" alwaysOn />,
  <TextInput source="id" label="User ID" alwaysOn />,
];

function getDeltaInDaysFromNow(targetDate) {
  const currentDate = new Date();

  // Set the current date's hours, minutes, seconds, and milliseconds to 0
  // to ensure a full day comparison.
  currentDate.setHours(0, 0, 0, 0);

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const differenceInMilliseconds = currentDate.getTime() - targetDate.getTime();

  return Math.round(differenceInMilliseconds / oneDayInMilliseconds);
}

const LastMessageDateField = (props) => {
  const record = useRecordContext(props);

  // ensure the record exists
  if (!record) {
    return null;
  }

  const value = record[props.source];
  let style = {};

  // Convert string to Date
  const dateValue = new Date(value);
  const delta = getDeltaInDaysFromNow(dateValue);

  if (delta < 8) {
    style.color = "green";
  } else if (delta < 15) {
    style.color = "blue";
  } else if (delta < 22) {
    style.color = "orange";
  } else {
    style.color = "red";
  }

  return <DateField style={style} {...props} />;
};

const CoachusersList = (props) => {
  return (
    <List {...props} pagination={<Pag />} perPage={200} filters={coachFilters}>
      <Datagrid rowClick="edit">
        <TextField source="nickname" />
        <TextField source="email" />
        <TextField source="group" />
        <TextField source="id" label="User ID" />
        <LastMessageDateField source="last_message" showTime={true} />
        <DateField source="first_message" showTime={true} />

        <NumberField source="count_read" label="Msgs Read By User" />
        <NumberField source="count_to_user" label="Msgs To User" />
        <NumberField source="count_from_user" label="Msgs From User" />
        <NumberField source="count_messages" label="Total Msgs" />

        {/* <BooleanField source="is_b2b" />
        <BooleanField source="real_user" />
        <BooleanField source="premium" />
        <TextField source="email_domains" />
        <TextField source="staff_notes" />
        <TextField source="status" />
        <BooleanField source="real_user" /> */}
      </Datagrid>
    </List>
  );
};

export default CoachusersList;
