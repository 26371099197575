import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
} from "react-admin";
import { Pag } from "../../utils/utils";

const QuizquestionsList = (props) => {
  return (
    <List {...props} pagination={<Pag />} perPage={200}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <NumberField source="order" />
        <TextField source="status" />
        <TextField source="question" />
        <TextField source="title" />
        <TextField source="description" />
      </Datagrid>
    </List>
  );
};

export default QuizquestionsList;
