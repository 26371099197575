import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  BooleanField,
  NumberField,
  DateField,
  Datagrid,
  ArrayField,
  useRecordContext,
  RecordContextProvider,
} from "react-admin";
import { Divider } from "@mui/material";

// Doesnt currently work correctly with nested fields that we need
const HKRecordsWrap = (props) => {
  const record = useRecordContext(props);
  if (!record || !record.hk_summary?.valid_records) {
    return <p>No HealthKit records</p>;
  }
  console.log("REC");
  console.log(record);
  record.hk_summary.valid_records = record.hk_summary.valid_records.reverse();
  return (
    <RecordContextProvider record={record}>
      <ArrayField source="hk_summary.valid_records">
        <Datagrid bulkActionButtons={false}>
          <DateField showTime={true} source="start_date" />
          <DateField showTime={true} source="end_date" />
          <TextField source="source" />
          <TextField source="timezone" />
          <NumberField source="type" />
        </Datagrid>
      </ArrayField>
    </RecordContextProvider>
  );
};

const LogShow = (props) => {
  return (
    <Show>
      <SimpleShowLayout
        spacing={2}
        divider={<Divider flexItem />}
        sx={{ "& .RaLabeled-label": { fontWeight: "bold" } }}
      >
        <h2>User's Daily Log</h2>
        <h3>Metadata and Settings</h3>

        <TextField source="id" />
        <TextField source="user_id" />
        <TextField source="day" />
        <TextField source="timezone" />

        <FunctionField
          label="Setting Bed"
          render={(r) => `${r.setting_bedtime_hour}: ${r.setting_bedtime_min}`}
        />
        <FunctionField
          label="Setting Wake"
          render={(r) => `${r.setting_wake_hour}: ${r.setting_wake_min}`}
        />

        <h3>HealthKit Summary</h3>
        <h5>Sleep/Wake times</h5>
        <DateField showTime={true} source="hk_summary.in_bed" />
        <DateField showTime={true} source="hk_summary.asleep" />
        <DateField showTime={true} source="hk_summary.wakeup" />
        <DateField showTime={true} source="hk_summary.out_of_bed" />
        <NumberField source="hk_summary.wake_up_times" />
        <NumberField source="hk_summary.wake_up_duration" />
        <h5>Source (device) info</h5>
        <TextField source="hk_summary.source_asleep" />
        <TextField source="hk_summary.source_awake" />
        <TextField source="hk_summary.source_in_bed" />
        <h5>All records used for this day</h5>
        {/* <HKRecordsWrap /> */}
        <ArrayField source="hk_summary.valid_records">
          <Datagrid bulkActionButtons={false}>
            <DateField showTime={true} source="start_date" />
            <DateField showTime={true} source="end_date" />
            <TextField source="source" />
            <TextField source="timezone" />
            <FunctionField
              label="Type"
              render={(r) => {
                switch (r.type) {
                  case 0:
                    return `in_bed (${r.type})`;
                  case 1:
                    return `asleep (${r.type})`;
                  case 2:
                    return `awake (${r.type})`;
                  default:
                    return `unknown (${r.type})`;
                }
              }}
            />
          </Datagrid>
        </ArrayField>

        <h3>Morning Journal</h3>

        <FunctionField
          label="InBed"
          render={(r) => `${r.in_bed_hour}:${r.in_bed_min}`}
        />
        <FunctionField
          label="Asleep"
          render={(r) => `${r.asleep_hour}:${r.asleep_min}`}
        />
        <NumberField source="fall_asleep_duration" />

        <FunctionField
          label="WakeUps"
          render={(r) => `${r.wake_up_times}/${r.wake_up_duration}`}
        />
        <FunctionField
          label="Awake"
          render={(r) => `${r.wake_up_hour}:${r.wake_up_min}`}
        />
        <NumberField source="asleep_duration" />

        <FunctionField
          label="Out of Bed"
          render={(r) => `${r.out_of_bed_hour}:${r.out_of_bed_min}`}
        />

        <TextField source="sleep_quality_choice" />

        <FunctionField
          label="Morning Journal?"
          render={(r) => `len: ${r.journal_sleep.length}`}
        />

        <BooleanField source="sleep_log_confirmed" />

        <h3>Evening Journal</h3>

        <TextField source="progress_choice" />
        <TextField source="irritable_choice" />

        <FunctionField
          label="Nap?"
          render={(r) => `${r.nap_hour}:${r.nap_min}`}
        />

        <NumberField source="nap_duration" />

        <FunctionField
          label="Evening Journal?"
          render={(r) => `len: ${r.journal_day.length}`}
        />

        <NumberField source="lesson_percent" />
        <NumberField source="session_percent" />
        <NumberField source="log_percent" />

        <NumberField source="lesson_day" />
        <NumberField source="lessons_completed" />
        <NumberField source="lesson_current_item" />
        <NumberField source="lesson_items_completed" />

        <NumberField source="session_seconds" />
        <NumberField source="sessions_completed" />
        <NumberField source="sounds_seconds" />
        <NumberField source="essentials" />
        <NumberField source="sleep_score" />
        <NumberField source="day_score" />
      </SimpleShowLayout>
    </Show>
  );
};

export default LogShow;
