import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  TextInput,
} from "react-admin";
import { Pag } from "../../utils/utils";

const userFilters = [
  <TextInput source="email" alwaysOn />,
  <TextInput source="nickname" alwaysOn />,
  <TextInput source="group" alwaysOn />,
  <TextInput source="initial_app" alwaysOn />,
];

const UsersList = (props) => {
  return (
    <List pagination={<Pag />} perPage={200} {...props} filters={userFilters}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" />
        <TextField source="group" />
        <TextField source="nickname" />
        <TextField source="email" />
        <TextField source="initial_app" />
        <DateField source="created" showTime={true} />
        <DateField source="updated" showTime={true} />
        <TextField source="timezone" />
        <TextField source="staff_status" label="Staff" />
        <TextField source="status" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default UsersList;
