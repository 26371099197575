import React from "react";
import { Pagination } from "react-admin";

export const Pag = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const apiBaseURL = () => {
  const domain = window.location.hostname;
  if (domain === "localhost") {
    return "http://localhost:1382"; // local
  }
  if (domain === "admin.chorussleep.com") {
    return "https://api.chorussleep.com"; // prod
  }
  return "https://api.chorsleep.dev"; // dev
};

export const webAppBaseURL = () => {
  const domain = window.location.hostname;
  if (domain === "localhost") {
    return "http://localhost:3000"; // local
  }
  if (domain === "admin.chorussleep.com") {
    return "https://app.chorussleep.com"; // prod
  }
  return "https://app.chorsleep.dev"; // dev
};

export const webFlappBaseURL = () => {
  const domain = window.location.hostname;
  if (domain === "localhost") {
    return "http://localhost:3000"; // local
  }
  if (domain === "admin.chorussleep.com") {
    return "https://flapp.chorussleep.com"; // prod
  }
  return "https://flapp.chorsleep.dev"; // dev
};
