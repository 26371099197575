import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
} from "react-admin";
import { mailMsgLinkTypes } from "../../data/common";

// Custom label with enlarged font (to help differentiate insights and resources)
const CustomLabel = ({ text }) => (
  <div style={{ fontSize: "1.5em", paddingBottom: "20px" }}>{text}</div>
);

const MailMsgLinkEdit = (props) => {
  return (
    <Edit title="Edit Link" {...props} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput source="id" disabled />
        <TextInput source="link" />
        <SelectInput source="type" choices={mailMsgLinkTypes} />
        <TextInput source="staff_notes" />
        <TextInput source="user_id" />
        <TextInput source="mail_msg_id" />
        <DateInput source="created" disabled />
        <DateInput source="updated" disabled />
      </SimpleForm>
    </Edit>
  );
};

export default MailMsgLinkEdit;
