import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
} from "react-admin";
import { Pag } from "../../utils/utils";

const MailmsgLinkList = (props) => {
  return (
    <List {...props} pagination={<Pag />} perPage={200}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="campaign_id" />
        <TextField source="type" />
        <TextField source="mail_msg_id" />
        <DateField source="created" />
        <DateField source="updated" />
      </Datagrid>
    </List>
  );
};

export default MailmsgLinkList;
