import React from "react";
import { TextField, DateField, SimpleShowLayout, Show } from "react-admin";
import { Divider } from "@mui/material";

const MailmsgShow = (props) => {
  return (
    <Show>
      <SimpleShowLayout
        spacing={2}
        divider={<Divider flexItem />}
        sx={{ "& .RaLabeled-label": { fontWeight: "bold" } }}
      >
        <TextField source="id" />
        <TextField source="user_id" />
        <TextField source="email_name" />
        <TextField source="from_id" />
        <TextField source="status" />
        <DateField source="created" />
        <DateField source="updated" />
      </SimpleShowLayout>
    </Show>
  );
};

export default MailmsgShow;
