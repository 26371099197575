import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TextInput,
  BooleanField,
  NumberField,
} from "react-admin";
import { Pag } from "../../utils/utils";

const filters = [
  <TextInput source="user_id" alwaysOn />,
  <TextInput source="day" alwaysOn />,
  <TextInput source="timezone" alwaysOn />,
];

const LogList = (props) => {
  return (
    <List pagination={<Pag />} perPage={200} {...props} filters={filters}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        {/* <TextField source="id" /> */}
        <TextField source="user_id" />
        <TextField source="day" />
        <TextField source="timezone" />
        <FunctionField
          label="SettBed"
          render={(r) => `${r.setting_bedtime_hour}: ${r.setting_bedtime_min}`}
        />
        <FunctionField
          label="SettWake"
          render={(r) => `${r.setting_wake_hour}: ${r.setting_wake_min}`}
        />

        <FunctionField
          label="InBed"
          render={(r) => `${r.in_bed_hour}: ${r.in_bed_min}`}
        />
        <FunctionField
          label="Asleep"
          render={(r) => `${r.asleep_hour}: ${r.asleep_min}`}
        />
        <FunctionField
          label="WakeUps"
          render={(r) => `${r.wake_up_times}/ ${r.wake_up_duration}`}
        />
        <FunctionField
          label="Awake"
          render={(r) => `${r.wake_up_hour}: ${r.wake_up_min}`}
        />
        <BooleanField source="sleep_log_confirmed" />

        <NumberField source="lesson_percent" />
        <NumberField source="session_percent" />
        <NumberField source="log_percent" />

        <NumberField source="lesson_day" />
        <NumberField source="lessons_completed" />
        {/* <NumberField source="lesson_current_item" />
        <NumberField source="lesson_items_completed" /> */}

        <NumberField source="session_seconds" />
        <NumberField source="sessions_completed" />
        <NumberField source="sounds_seconds" />
        <NumberField source="essentials" />
        <NumberField source="sleep_score" />
        <NumberField source="day_score" />
      </Datagrid>
    </List>
  );
};

export default LogList;
