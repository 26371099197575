import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";

const HKRawList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <DateField source="start_date" />
        <DateField source="end_date" />
      </Datagrid>
    </List>
  );
};

export default HKRawList;
