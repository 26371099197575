import { apiBase } from "./api/requests";
import { setItem, getItem, removeItem } from "./utils/localStorage";

const authProvider = {
  login: ({ username, password }) => {
    console.log("[authProvider] calling login");
    const request = new Request(`${apiBase()}/admin/auth`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setItem("user", data.body.user);
        setItem("auth_token", data.auth_token);
        setItem("stream_token", data.body.stream_token);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  logout: () => {
    console.log("[authProvider] calling logout");
    removeItem("user");
    removeItem("auth_token");
    removeItem("stream_token");
    return Promise.resolve();
  },
  checkError: (error) => {
    console.log("[authProvider] calling checkError");
    const status = error.status;
    if (status === 401 || status === 403) {
      console.log("[authProvider] Auth failed, logging user out");
      removeItem("user");
      removeItem("auth_token");
      removeItem("stream_token");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    console.log("[authProvider] calling checkAuth");
    const authToken = getItem("auth_token");
    return authToken ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => Promise.reject("Unknown method"),
  getIdentity: () => {
    console.log("[authProvider] calling getIdentity");
    const user = getItem("user");
    return Promise.resolve(user);
  },
};

export default authProvider;
